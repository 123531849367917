<template>
    <div id="projectPortfolio">
        <transition name="opacityShow">
            <div id="projectBigImg" v-if="!scrolledDown">
                <img src="https://ik.imagekit.io/nnol5se8mol/tr:w-1440,h-806/interhigh-desktop2_RfNz0caI_.png" alt="">
            </div>
        </transition>
        <div class="contentProjectPort"></div>
        <div class="contentProjectPort">
            <div class="contentFlex1">
                <h2>INTERHIGH SCHOOL</h2>
                <p>Established as a complete online secondary school since 2005 and the largest of its kind in the UK, InterHigh delivers a fully interactive, accredited British education to UK and overseas students across KS3, KS4 and KS5. Students are welcomed from all ability levels and can study from a range of 24 subject areas.</p>
            </div>
            <div class="contentFlex2">
                <div>
                    <h3>THE CHALLENGE</h3>
                    <p>The challenge was to create a fully-fledged, attention-grabbing campaign to be featured on Waterloo Motion and throughout the transport stations. </p>
                    <p>The goal was to increase enrollment for InterHigh, to raise awareness about it and encourage parents to explore and discover InterHigh as a new option for their children for the school term.</p>
                </div>
                <div>
                    <h3>THE SOLUTION</h3>
                    <p>Focused around the idea of a digital school that students can attend from the comfort of their own home, abroad or elsewhere, the advert featured graphical patterns and bold elements that convey a digital age feel combined with deep gradient backgrounds to help emphasize the text and message.</p>
                    <p>As the advert had a limited 20 seconds in length, it meant that it had to be easy to understand and strong in their messages. It had to be very short and straightforward — leading the advert in with intrigue, hitting the selling points of the school’s quality and flexibility and allowing the person to understand what the school is. 
                    </p>
                    <p>The final campaign was displayed throughout Waterloo Station in London, catching parents on their commute.</p>
                </div>
            </div>
        </div>
        <div class="contentProjectPort" id="mediaSection">
            <img src='https://ik.imagekit.io/nnol5se8mol/tr:w-1440,h-806/portfolio1_n3WdUB4wk.png'>
        </div>
        <div class="contentProjectPort">
            <div class="btn tellusaboutyourprojectbtn animated delay-1s" @click="$store.state.modalOpened = true">
                <div></div>
                <span>Contact Clement for a free quote.</span>
            </div>
        </div>
        <footerComp/>
    </div>
</template>

<script>
export default {

  mounted() {
    document.body.style.height = "auto"
    window.scrollTo(0, 0)
  },

  destroyed() {
    document.body.style.height = "1000vh"
  },

  computed: {
      scrolledDown() {
          return this.$store.state.offsetTop
      }
  }
}
</script>

<style lang="sass">

$color1: rgba(38, 70, 83, 1)
$color2: rgba(42, 157, 143, 1)
$color3: rgba(233, 196, 106, 1)
$color4: rgba(244, 162, 97, 1)
$color5: rgba(231, 111, 81, 1)

#projectPortfolio
  background-color: #2F0167
  width: 100vw
  position: absolute
  left: 0
  top: 0
  #footer
    background-color: transparent !important
    .ftop
        .btn
            &:hover
                span
                    color: #2F0167 !important
  #projectBigImg
    width: 100vw
    height: 100vh
    position: fixed
    z-index: 200
    img
        background-size: cover

.opacityShow-enter-active, .opacityShow-leave-active 
  transition: all 1s ease-in-out

.opacityShow-enter, .opacityShow-leave-to
  opacity: 0

.contentProjectPort
    width: 70vw
    height: 100vh
    display: flex
    flex-direction: column
    padding: 0 15vw
    margin: auto
    *
        text-align: left !important
    .tellusaboutyourprojectbtn
        width: 700px
        height: 180px
        margin: auto
        span
            font-size: 40px
            margin-top: 4px
            text-align: center !important
        &:hover
            span
                color: #2F0167 !important

.contentFlex1
    display: flex
    flex-direction: column
    h2
        margin-bottom: 0
        font-size: 80px
    p
        font-size: 30px

.contentFlex2
    display: flex
    div
        margin: 20px 50px 0 0

#mediaSection
    width: 100vw !important
    height: auto
    padding: 0 !important
    img
        margin: auto
        width: 100%
        height: 100%
</style>